<template>
    <div class="reporting">
        <b-row>
            <b-col cols="12">
                <ReportFilter @view="loadReport" :loading="loading" />
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="12">
                <ReportTable v-if="report !== null" :items="report" @show-report="showReport" @access-dashboard="loginAs" />
            </b-col>
        </b-row>
        <GraphicalReport ref="graphicalReport" />
    </div>
</template>

<script>

    import ReportFilter from "../../components/manager/reporting/ReportFilter";
    import Providers from '../../providers';
    import ReportTable from "../../components/manager/reporting/ReportTable";
    import GraphicalReport from "../../components/manager/reporting/GraphicalReport";
    import Managers from "@/managers";

    export default {
        name: "Reporting",
        components: {ReportTable, ReportFilter, GraphicalReport},
        data() {
            return {
                loading: false,
                report: null,
                range: null
            }
        },
        methods: {
            loadReport({unit, facility, role, mentor, dateFrom, dateTo}) {
                this.loading = true;
                this.report = null;
                this.range = {from: dateFrom, to: dateTo};
                Providers.manager.reporting.report(unit, facility, role, mentor, dateFrom, dateTo).then((resp) => {
                    this.report = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load report', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            showReport(user) {
                this.$refs.graphicalReport.show(user.firstName, user.lastName, user.userId, this.range.from, this.range.to);
            },
            loginAs(userId) {
                Managers.authentication.overrideLogin(userId).catch((resp) => {
                    this.$httpError('Failed to open users dashboard', resp);
                });
            }
        }
    }
</script>

<style scoped>

</style>
