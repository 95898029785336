<template>
    <div class="reporting-filter">
        <b-form inline class="reporting-filter-form">
            <Dropdown variant="dark" :options="units" title="Unit" v-model="unit" empty-title="- All Units -" include-empty :disabled="facility" fill />
            <Dropdown variant="dark" :options="facilities" title="Facility" v-model="facility" empty-title="- All Facilities -" include-empty :disabled="unit" fill />
            <Dropdown variant="dark" :options="roles" title="Role" v-model="role" empty-title="- All Roles -" include-empty fill />
            <v-select :options="mentors" :reduce="opt => opt.id" v-model="mentor" label="title" placeholder="-- All Mentors --" clearable style="min-width: 200px;" />
            <b-form-datepicker placeholder="Start Date" v-model="dateFrom"></b-form-datepicker> <span>to</span> <b-form-datepicker placeholder="End Date" v-model="dateTo"></b-form-datepicker>
            <LoadingButton @click="view" :loading="loading" :disabled="!canView">View Report</LoadingButton>
        </b-form>
    </div>
</template>

<script>
    import Dropdown from "../../common/Dropdown";
    import LoadingButton from 'cpdcloud-dashboard/src/components/common/LoadingButton';
    import Providers from '../../../providers';
    import VSelect from 'vue-select';

    export default {
        name: "ReportFilter",
        components: {Dropdown, LoadingButton, VSelect},
        props: {
            loading: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        data() {
            return {
                unit: null,
                facility: null,
                role: null,
                mentor: null,
                dateFrom: null,
                dateTo: null,
                units: [],
                facilities: [],
                roles: [],
                mentors: [],
            }
        },
        computed: {
            canView() {
                return this.dateFrom !== null && this.dateTo !== null
            }
        },
        methods: {
            loadFilters() {
                Providers.manager.reporting.filterOptions().then((resp) => {
                    this.roles = resp.body.roles.map(role => ({title: role.name, value: role.id}));
                    this.facilities = resp.body.facilities.map(facility => ({title: facility.name, value: facility.id}));
                    this.units = resp.body.units.map(unit => ({title: unit.name, value: unit.id}));
                    this.mentors = resp.body.mentors.map(mentor => ({title: mentor.name, id: mentor.userId}));
                }).catch((resp) => {
                    this.$httpError('Failed to load filters', resp);
                });
            },
            view() {
                this.$emit('view', {unit: this.unit, facility: this.facility, role: this.role, mentor: this.mentor, dateFrom: this.dateFrom, dateTo: this.dateTo});
            }
        },
        created() {
            this.loadFilters();
            this.dateFrom = this.moment().subtract(1, 'year').format('YYYY-MM-DD');
            this.dateTo = this.moment().format('YYYY-MM-DD');
        }

    }
</script>

<style scoped>
    .reporting-filter-form > * {
        margin-right: 5px;
    }

    .reporting-filter-form > .dropdown {
        min-width: 150px;
    }
</style>
