<template>
    <div class="graphical-report">
        <b-modal :title="title" size="full-screen" class="full-screen" ref="modal" hide-footer>

            <div class="statistics" v-if="statistics">
                <b-row>
                    <b-col cols="12">
                        <TopStatistics :statistics="statistics.entries" v-if="statistics"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6" class="mt-4">
                        <PieChartWidget title="Total Hours of CPD Captured" :category-data="statistics.cpdHours" />
                    </b-col>
                    <b-col md="6" class="mt-4">
                        <PieChartWidget title="Total Hours of Clinical Exposure Captured" :category-data="statistics.cceHours" />
                    </b-col>
                    <b-col md="6" class="mt-4">
                        <LineChartWidget title="Hours of Activity Per Month" :line-data="statistics.hoursOverTime" y-axis-label="Hours of Activity" />
                    </b-col>
                    <b-col md="6" class="mt-4">
                        <LineChartWidget title="Entries Submitted Per Month" :line-data="statistics.activityOverTime" y-axis-label="Number of Entries" />
                    </b-col>
                    <b-col md="6" class="mt-4">
                        <PieChartWidget title="Approval Statuses" :category-data="statistics.statuses" />
                    </b-col>
                </b-row>
            </div>

            <div class="text-center" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
            </div>

        </b-modal>


    </div>
</template>

<script>
    import Providers from '../../../providers';
    import PieChartWidget from "../../home/widgets/PieChartWidget";
    import LineChartWidget from "../../home/widgets/LineChartWidget";
    import TopStatistics from "../../home/TopStatistics";

    export default {
        name: "GraphicalReport",
        components: {PieChartWidget, LineChartWidget, TopStatistics},
        data() {
            return {
                loading: false,
                name: '',
                userId: null,
                statistics: null,
                dateFrom: '',
                dateTo: ''
            }
        },
        computed: {
            title() {
                return `Graphical Report - ${this.name}`
            }
        },
        methods: {
            show(firstName, lastName, userId, dateFrom, dateTo) {
                this.name = `${firstName} ${lastName}`;
                this.dateFrom = dateFrom;
                this.dateTo = dateTo;
                this.userId = userId;
                this.loadStatistics();
                this.$refs.modal.show();
            },
            loadStatistics() {
                this.loading = true;
                Providers.statistics.statistics({from: this.dateFrom, to: this.dateTo}, this.userId).then((resp) => {
                    this.statistics = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load statistics', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        }

    }
</script>

<style scoped>

</style>