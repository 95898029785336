<template>
    <div class="report-table">
        <b-table :fields="fields" :items="items" show-empty responsive>
            <template v-slot:empty>
                <b-alert show variant="primary">
                    No matching items
                </b-alert>
            </template>
            <template v-slot:cell(actions)="data">
                <b-button-group>
                    <b-button variant="outline-dark" size="sm" @click="showReport(data.item)" ><FileChartOutlineIcon /> Graphical Report</b-button>
                    <b-dropdown title="More Actions" variant="outline-dark" size="sm">
                        <b-dropdown-item size="sm" @click="accessDashboard(data.item.userId)"><BookOpenOutlineIcon /> Access Dashboard</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>

            </template>
        </b-table>
    </div>
</template>

<script>
    import FileChartOutlineIcon from 'vue-material-design-icons/FileChartOutline'
    import BookOpenOutlineIcon from 'vue-material-design-icons/BookOpenOutline';

    export default {
        name: "ReportTable",
        components: {FileChartOutlineIcon, BookOpenOutlineIcon},
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                fields: [
                    {
                        label: 'Name',
                        key: 'name',
                        sortable: true,
                        sortByFormatted: true,
                        formatter: this.formatterName
                    },
                    {
                        label: 'CPD Hours',
                        key: 'cpdHours',
                        sortable: true
                    },
                    {
                        label: 'CCE Hours',
                        key: 'cceHours',
                        sortable: true
                    },
                    {
                        label: 'Skills Utilisation',
                        key: 'skillsStatus',
                        formatter: this.formatterPercentage,
                        sortable: true
                    },
                    {
                        label: 'Training Status',
                        key: 'trainingStatus',
                        formatter: this.formatterPercentage,
                        sortable: true
                    },
                    {
                        label: 'Last Activity',
                        key: 'lastActivity',
                        formatter: this.formatterDate,
                        sortable: true
                    },
                    {
                        label: 'Mentors',
                        key: 'mentors',
                        formatter: this.formatterMentors
                    },
                    {
                        label: 'End of Reporting Period',
                        key: 'endOfReportingPeriod',
                        formatter: this.formatterDate,
                        sortable: true
                    },
                    {
                        label: 'Actions',
                        key: 'actions'
                    }
                ]
            }
        },
        methods: {
            formatterPercentage(val) {
                if (val.toFixed) {
                    return `${val.toFixed(0)}%`;
                }
                return `${val}%`;
            },
            formatterDate(val) {
                return this.moment(val).format('DD/MM/YYYY');
            },
            formatterName(val, key, item) {
                return `${item.firstName} ${item.lastName}`
            },
            formatterMentors(val) {
                return val.join(', ');
            },
            showReport(user) {
                this.$emit('show-report', user);
            },
            accessDashboard(userId) {
                this.$emit('access-dashboard', userId);
            }
        }
    }
</script>

<style scoped>

</style>
